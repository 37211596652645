import request from "./axios"

// 接口请求示例
export function generateSignature(data) {
    return request({
        url: 'channel/weChatH5/generateSignature',
        method: 'POST',
        data
    })
}
export function getAgreement(params) {
    return request({
        url: params,
        method: 'GET',
    })
}
// 图片--上传
export function UploadImg(data) {
    return request({
      url: `file/uploadIdCardImg`,
      method: 'post',
      data
    })
  }
  
  export function gatherIdCard(data) {
    return request({
      url: `member/person/gatherIdCard`,
      method: 'post',
      data
    })
  }
  
    
  export function clusterGatherIdCard(data) {
    return request({
      url: `member/person/clusterIdCardAuth`,
      method: 'post',
      data
    })
  }
  export function uploadIdCardImg(data) {
    return request({
      url: `/file/uploadIdCardImg`,
      method: 'post',
      data
    })
  }

